@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.tutorial-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Dim the rest of the screen */
  z-index: 10000; /* Below Joyride spotlight */
  pointer-events: none; /* Prevent clicks on the overlay */
}

.tutorial-overlay.tutorial-active {
  pointer-events: none; /* Ensure no clicks on the overlay */
}

.joyride__spotlight {
  pointer-events: auto !important; /* Allow clicks on spotlighted elements */
  z-index: 10001; /* Ensure it's above the overlay */
}

.create-conversation-btn {
  z-index: 10002 !important; /* Ensure the button is above the spotlight */
}

.MuiFormLabel-root.Mui-focused {
  color: gray !important;
}

