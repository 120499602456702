@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Poppins:wght@400;500;600;700&display=swap');

:root {
    --primary: #DE3334;
    --primary-dark: #B82829;
    --primary-50: rgba(222, 51, 52, 0.05);
    --primary-100: rgba(222, 51, 52, 0.1);
    --primary-600: #DE3334;
    --primary-700: #B82829;
    --secondary: #233B6B;
    --light: #F8FAFF;
    --gray: #EEF1F9;
    --dark: #1A2035;
    --text: #4A5568;
    --foreground: #1A2035;
    --muted-foreground: #64748B;
    --border: #E2E8F0;
    --shadow-subtle: 0 4px 10px rgba(0, 0, 0, 0.04);
}

/* Base styles */
.pricing {
    padding-top: 50px;
    background: var(--light);
    font-family: 'Inter', sans-serif;
    color: var(--text);
    line-height: 1.6;
}

.container {
    width: 100%;
    max-width: 100%;
    padding-left: 80px;
    padding-right: 80px;
}

/* Section title */
.section-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--secondary);
    margin-bottom: 1rem;
    text-align: center;
    position: relative;
    padding-bottom: 15px;
    font-family: 'Poppins', sans-serif;
}

.section-title::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 60px;
    height: 3px;
    background: var(--primary);
    transform: translateX(-50%);
}

.section-subtitle {
    text-align: center;
    color: var(--muted-foreground);
    font-size: 1.1rem;
    max-width: 700px;
    margin: 0 auto 3rem;
}

/* Toggle */
.pricing-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    gap: 20px;
}

.toggle-label {
    font-weight: 500;
}

.toggle-switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
}

.toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--primary);
    transition: .4s;
    border-radius: 30px;
}

.toggle-slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked+.toggle-slider:before {
    transform: translateX(30px);
}

.discount-label {
    background: rgba(222, 51, 52, 0.1);
    color: var(--primary);
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 0.8rem;
    font-weight: 500;
}

/* Pricing cards grid */
.pricing-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    width: 100%;
    max-width: 100%;
}

.pricing-card {
    background: white;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: var(--shadow-subtle);
    transition: all 0.3s ease;
    position: relative;
    border: 1px solid var(--border);
    display: flex;
    flex-direction: column;
}

.pricing-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.pricing-card.popular {
    border-color: var(--primary);
    box-shadow: 0 10px 30px rgba(222, 51, 52, 0.1);
    z-index: 1;
}

.pricing-card.popular:before {
    content: "Le plus populaire";
    position: absolute;
    top: 15px;
    right: 0;
    background: var(--primary);
    color: white;
    padding: 5px 15px;
    font-size: 0.8rem;
    border-radius: 20px 0 0 20px;
}

/* Pricing Header */
.pricing-header {
    padding: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--gray);
    text-align: left;
}

.pricing-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
    text-align: left;
    font-family: 'Poppins', sans-serif;
    color: var(--secondary);
}

.pricing-subtitle {
    font-size: 0.9rem;
    opacity: 0.7;
    margin-bottom: 20px;
    text-align: left;
}

.pricing-price-container {
    display: flex;
    align-items: baseline;
    gap: 5px;
    margin-bottom: 5px;
}

.pricing-price {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 5px;
    text-align: left;
    display: inline-block;
    margin-right: 5px;
    color: var(--secondary);
}

.pricing-period {
    display: inline-block;
    font-size: 0.9rem;
    opacity: 0.7;
    text-align: left;
}

.pricing-monthly {
    font-size: 0.9rem;
    margin-top: 5px;
    color: var(--primary);
    text-align: left;
}

/* Features list */
.pricing-features {
    padding: 15px 30px 30px;
    list-style: none;
    margin-top: 0;
    padding-left: 15px;
}

.pricing-features li {
    margin-bottom: 15px;
    position: relative;
    padding-left: 30px;
    display: flex;
    align-items: flex-start;
    text-align: left;
}

.pricing-features li:before {
    content: "✓";
    position: absolute;
    left: 0;
    color: var(--primary);
    font-weight: bold;
}

/* Footer with button */
.pricing-footer {
    padding: 0 30px 30px;
    text-align: left;
    margin-top: auto;
}

.btn {
    display: inline-block;
    padding: 12px 24px;
    background: var(--primary);
    color: white;
    border-radius: 30px;
    text-decoration: none;
    font-weight: 500;
    transition: all 0.3s ease;
    border: none;
    cursor: pointer;
}

.btn:hover {
    background: var(--primary-dark);
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

/* Default button styles for non-popular cards */
.pricing-card:not(.popular) .pricing-footer .btn {
    background: transparent;
    border: 2px solid var(--primary);
    color: var(--primary);
}

.pricing-card:not(.popular) .pricing-footer .btn:hover {
    background: var(--primary-50);
    color: var(--primary-700);
}

/* Ensure popular card buttons keep the standard style */
.pricing-card.popular .pricing-footer .btn {
    background: var(--primary);
    color: white;
    border: 2px solid var(--primary);
}

.pricing-card.popular .pricing-footer .btn:hover {
    background: var(--primary-dark);
    color: white;
}

/* Responsive styles */
@media (max-width: 992px) {
    .pricing-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .container {
        padding-left: 40px;
        padding-right: 40px;
    }
}

@media (max-width: 768px) {
    .pricing-grid {
        grid-template-columns: 1fr;
    }

    .section-title {
        font-size: 2rem;
    }

    .container {
        padding-left: 20px;
        padding-right: 20px;
    }
}

/* Add these styles to your existing PricingScreen.css file */

/* Current plan highlighting */
.pricing-card.current-plan {
    border: 2px solid #4A90E2;
    box-shadow: 0 8px 24px rgba(74, 144, 226, 0.2);
    transform: scale(1.02);
    position: relative;
    overflow: visible;
}

.current-plan-badge {
    position: absolute;
    top: -12px;
    right: 20px;
    background-color: #4A90E2;
    color: white;
    padding: 5px 15px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 600;
    z-index: 2;
}

/* Disabled button for current plan */
.btn-disabled {
    background-color: #e0e0e0;
    cursor: not-allowed;
    color: #666;
    border: none;
}

.btn-disabled:hover {
    background-color: #e0e0e0;
    transform: none;
}

/* Fade effect for loading state */
.pricing-card.loading {
    opacity: 0.7;
    pointer-events: none;
}

/* Plan change button variations */
.btn-upgrade {
    background-color: #4CAF50;
}

.btn-downgrade {
    background-color: #F9A825;
}

.btn-cycle {
    background-color: #7B68EE;
}