/* Editor Container Styles */
.editor-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  background-color: #f9f9f9;
}

/* Tiptap Editor Base Styles */
.tiptap-editor {
  flex: 1;
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  padding: 3rem 6rem;
  overflow-y: auto;
  background-color: white;
  border-radius: 0.25rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.tiptap-editor:focus {
  outline: none;
}

/* Typography */
.tiptap-editor h1 {
  font-size: 2.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
  line-height: 1.2;
}

.tiptap-editor h2 {
  font-size: 2rem;
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
  font-weight: 600;
  line-height: 1.25;
}

.tiptap-editor h3 {
  font-size: 1.5rem;
  margin-top: 1.25rem;
  margin-bottom: 0.75rem;
  font-weight: 600;
  line-height: 1.3;
}

.tiptap-editor h4 {
  font-size: 1.25rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.4;
}

.tiptap-editor h5 {
  font-size: 1.125rem;
  margin-top: 0.875rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.4;
}

.tiptap-editor p {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  line-height: 1.6;
}

/* Task Lists */
.tiptap-editor ul[data-type="taskList"] {
  list-style: none;
  padding: 0;
}

.tiptap-editor ul[data-type="taskList"] li {
  display: flex;
  align-items: flex-start;
  margin: 0.5rem 0;
}

.tiptap-editor ul[data-type="taskList"] li>label {
  margin-right: 0.5rem;
  user-select: none;
}

.tiptap-editor ul[data-type="taskList"] li>div {
  flex: 1;
}

/* Blockquote */
.tiptap-editor blockquote {
  border-left: 3px solid #e0e0e0;
  padding-left: 1rem;
  margin-left: 0;
  margin-right: 0;
  font-style: italic;
  color: #5a5a5a;
}

/* Code */
.tiptap-editor code {
  background-color: #f5f5f5;
  border-radius: 3px;
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  font-family: 'Courier New', Courier, monospace;
}

/* Horizontal Rule */
.tiptap-editor hr {
  border: none;
  border-top: 2px solid #e0e0e0;
  margin: 2rem 0;
}

/* Tables */
/* Improved table styles with width constraints */
.tiptap-editor table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  margin: 1rem 0;
  table-layout: fixed;
  /* This is crucial - forces the table to respect width constraints */
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  /* Include border in width calculation */
  overflow-x: auto;
  /* Allow horizontal scrolling if needed within the container */
}

/* For small screens, make table scrollable */
@media (max-width: 768px) {
  .tiptap-editor .ProseMirror {
    overflow-x: auto;
  }

  .tiptap-editor table {
    width: 100%;
    min-width: 500px;
    /* Ensure minimum width for small screens */
  }
}

.tiptap-editor table th,
.tiptap-editor table td {
  border: 1px solid #e0e0e0;
  padding: 0.5rem;
  position: relative;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  /* Prevent text from overflowing */
  max-width: 200px;
  /* Limit cell width */
}

.tiptap-editor table th {
  background-color: #f5f5f5;
  font-weight: 600;
}

/* Set width for specific numbered columns to distribute space more evenly */
.tiptap-editor table td:nth-child(1),
.tiptap-editor table th:nth-child(1) {
  width: 20%;
}

.tiptap-editor table td:nth-child(2),
.tiptap-editor table th:nth-child(2) {
  width: 30%;
}

.tiptap-editor table td:nth-child(3),
.tiptap-editor table th:nth-child(3) {
  width: 30%;
}

.tiptap-editor table td:nth-child(n+4),
.tiptap-editor table th:nth-child(n+4) {
  width: 20%;
}

/* Make sure tables appear properly on all browsers */
.tiptap-editor .ProseMirror table {
  display: table;
  width: 100% !important;
  max-width: 100% !important;
}

.tiptap-editor .ProseMirror table tr {
  display: table-row;
}

.tiptap-editor .ProseMirror table td,
.tiptap-editor .ProseMirror table th {
  display: table-cell;
}

/* Improved table selection styles */
.tiptap-editor table .selectedCell {
  background-color: rgba(200, 200, 255, 0.3);
}

/* Fix for table resizing */
.tiptap-editor .column-resize-handle {
  position: absolute;
  right: -2px;
  top: 0;
  bottom: 0;
  width: 4px;
  background-color: #68cef8;
  cursor: col-resize;
  z-index: 20;
}

/* Custom class for tables that might need horizontal scrolling */
.tiptap-editor .table-container {
  width: 100%;
  overflow-x: auto;
  margin: 1rem 0;
}

/* Target the ProseMirror editor directly to add necessary overflow handling */
.tiptap-editor .ProseMirror {
  overflow-x: visible;
  overflow-y: auto;
}


/* Images */
.tiptap-editor img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 1rem auto;
}

.tiptap-editor img.ProseMirror-selectednode {
  outline: 2px solid #68cef8;
}

/* Image caption */
.tiptap-editor .image-caption {
  text-align: center;
  font-size: 0.875rem;
  color: #6b7280;
  margin-top: 0.5rem;
}

/* Image resize handles */
.tiptap-editor .image-resizer {
  position: absolute;
  border: 1px solid #68cef8;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.tiptap-editor .image-resizer .resize-handle {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: white;
  border: 1px solid #68cef8;
  pointer-events: all;
}

.tiptap-editor .image-resizer .resize-handle.top-left {
  top: -5px;
  left: -5px;
  cursor: nwse-resize;
}

.tiptap-editor .image-resizer .resize-handle.top-right {
  top: -5px;
  right: -5px;
  cursor: nesw-resize;
}

.tiptap-editor .image-resizer .resize-handle.bottom-left {
  bottom: -5px;
  left: -5px;
  cursor: nesw-resize;
}

.tiptap-editor .image-resizer .resize-handle.bottom-right {
  bottom: -5px;
  right: -5px;
  cursor: nwse-resize;
}

/* Text Alignment */
.tiptap-editor .text-left {
  text-align: left;
}

.tiptap-editor .text-center {
  text-align: center;
}

.tiptap-editor .text-right {
  text-align: right;
}

.tiptap-editor .text-justify {
  text-align: justify;
}

/* Highlight */
.tiptap-editor mark {
  background-color: #fef3c7;
  padding: 0.1rem 0;
}

/* Placeholder */
.tiptap-editor p.is-editor-empty:first-of-type::before {
  content: attr(data-placeholder);
  float: left;
  color: #9ca3af;
  pointer-events: none;
  height: 0;
}

/* Collaboration cursor */
.collaboration-cursor__caret {
  position: relative;
  border-left: 2px solid;
  border-right: none;
  margin-left: -1px;
  margin-right: -1px;
  pointer-events: none;
  word-break: normal;
  height: 1.2em;
  background-color: transparent;
  z-index: 50;
  animation: blink 1s infinite;
}

.collaboration-cursor__label {
  position: absolute;
  top: -1.4em;
  left: -1px;
  border-radius: 3px 3px 3px 0;
  padding: 0.1rem 0.3rem;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap;
  color: white;
  user-select: none;
  pointer-events: none;
  z-index: 51;
}

.collaboration-cursor__selection {
  background-color: inherit;
  opacity: 0.3;
  pointer-events: none;
  position: relative;
  z-index: 20;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .tiptap-editor {
    padding: 0.75rem;
  }

  .tiptap-editor h1 {
    font-size: 2rem;
  }

  .tiptap-editor h2 {
    font-size: 1.75rem;
  }

  .tiptap-editor h3 {
    font-size: 1.5rem;
  }

  .tiptap-editor h4 {
    font-size: 1.25rem;
  }

  .tiptap-editor h5 {
    font-size: 1.125rem;
  }
}


/* Text color styles */
.tiptap [style*="color:"] {
  color: inherit;
}

/* Typography styles */
.tiptap [style*="font-size:"] {
  font-size: inherit;
}

.tiptap [style*="font-family:"] {
  font-family: inherit;
}

/* Color menu styles */
.MuiMenuItem-root .color-swatch {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
}

/* Add color indicator to the text color button when active */
.text-color-button-active {
  position: relative;
}

.text-color-button-active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%);
  width: 16px;
  height: 2px;
  background-color: currentColor;
}

/* Typography dropdown styles */
.typography-sample {
  font-size: inherit;
  font-family: inherit;
  margin-right: 10px;
}

/* Font size samples */
.font-size-small {
  font-size: 12px;
}

.font-size-normal {
  font-size: 16px;
}

.font-size-large {
  font-size: 20px;
}

.font-size-xlarge {
  font-size: 24px;
}

/* Font family samples */
.font-family-arial {
  font-family: Arial, sans-serif;
}

.font-family-times {
  font-family: 'Times New Roman', serif;
}

.font-family-courier {
  font-family: 'Courier New', monospace;
}

.font-family-georgia {
  font-family: Georgia, serif;
}

.font-family-verdana {
  font-family: Verdana, sans-serif;
}


/* Enhanced List Styles */
.tiptap-editor ul[data-type="bulletList"],
.tiptap-editor .bullet-list {
  list-style-type: disc;
  padding-left: 1.5rem;
  margin: 1rem 0;
}

.tiptap-editor ol[data-type="orderedList"],
.tiptap-editor .ordered-list {
  list-style-type: decimal;
  padding-left: 1.5rem;
  margin: 1rem 0;
}

.tiptap-editor .list-item {
  margin: 0.25rem 0;
  padding: 0.1rem 0;
}

.tiptap-editor ul[data-type="bulletList"] li,
.tiptap-editor ol[data-type="orderedList"] li,
.tiptap-editor .bullet-list li,
.tiptap-editor .ordered-list li {
  margin: 0.25rem 0;
  position: relative;
}

.tiptap-editor ul[data-type="bulletList"] li p,
.tiptap-editor ol[data-type="orderedList"] li p,
.tiptap-editor .bullet-list li p,
.tiptap-editor .ordered-list li p {
  margin: 0;
  line-height: 1.6;
}

/* Nested list styles */
.tiptap-editor ul[data-type="bulletList"] ul[data-type="bulletList"],
.tiptap-editor .bullet-list .bullet-list {
  list-style-type: circle;
}

.tiptap-editor ul[data-type="bulletList"] ul[data-type="bulletList"] ul[data-type="bulletList"],
.tiptap-editor .bullet-list .bullet-list .bullet-list {
  list-style-type: square;
}

.tiptap-editor ol[data-type="orderedList"] ol[data-type="orderedList"],
.tiptap-editor .ordered-list .ordered-list {
  list-style-type: lower-alpha;
}

.tiptap-editor ol[data-type="orderedList"] ol[data-type="orderedList"] ol[data-type="orderedList"],
.tiptap-editor .ordered-list .ordered-list .ordered-list {
  list-style-type: lower-roman;
}


/* ResizableImage.css */
.resizable-image-wrapper {
  position: relative;
  display: inline-block;
  max-width: 100%;
  margin: 1em 0;
}

.resizable-image-wrapper img {
  display: block;
  max-width: 100%;
}

.resize-handle {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #1976D2;
  border-radius: 50%;
  border: 1px solid white;
  z-index: 100;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s ease;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

.resizable-image-wrapper.hovered .resize-handle,
.resizable-image-wrapper.resizing .resize-handle {
  opacity: 1;
}

.resizable-image-wrapper.resizing {
  user-select: none;
}

.resizable-image-wrapper.hovered:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px dashed rgba(25, 118, 210, 0.5);
  pointer-events: none;
}

.handle-n {
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  cursor: n-resize;
}

.handle-ne {
  top: -5px;
  right: -5px;
  cursor: ne-resize;
}

.handle-e {
  top: 50%;
  right: -5px;
  transform: translateY(-50%);
  cursor: e-resize;
}

.handle-se {
  bottom: -5px;
  right: -5px;
  cursor: se-resize;
}

.handle-s {
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  cursor: s-resize;
}

.handle-sw {
  bottom: -5px;
  left: -5px;
  cursor: sw-resize;
}

.handle-w {
  top: 50%;
  left: -5px;
  transform: translateY(-50%);
  cursor: w-resize;
}

.handle-nw {
  top: -5px;
  left: -5px;
  cursor: nw-resize;
}



/* Existing Editor.css styles... */
/* Risk highlight styling - removed animations for immediate color display */
.risk-highlight {
  /* Color will be applied inline via style attribute */
}

/* Removed animation keyframes since we want immediate display */

/* Optional: Risk highlight backdrop with dynamic color support */
.risk-highlight-backdrop {
  /* Background color will be applied inline */
  border-radius: 4px;
  z-index: 10;
  pointer-events: none;
  animation: backdrop-pulse 2s ease-in-out;
}

@keyframes backdrop-pulse {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0.7;
  }

  80% {
    opacity: 0.7;
  }

  100% {
    opacity: 0;
  }
}

/* Mark the selection with a stronger visual indicator - updated for dynamic colors */
.ProseMirror .selection.risk-highlight {
  /* Color will be applied inline */
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
  /* No animation, colors should appear immediately */
}

/* Blinking attention indicator - updated for dynamic colors */
.risk-attention-indicator {
  position: absolute;
  z-index: 50;
  border-radius: 50%;
  /* Background color will be applied inline */
  transform: translate(-50%, -50%);
  pointer-events: none;
  animation: attention-ring 1s ease-out;
}

@keyframes attention-ring {
  0% {
    width: 10px;
    height: 10px;
    opacity: 0.8;
  }

  100% {
    width: 70px;
    height: 70px;
    opacity: 0;
  }
}


/* Updated Toolbar Styles - Remove border, padding and margin */
.toolbar {
  /* border: none !important; */
  padding: 0 !important;
  margin: 0 !important;
  z-index: 20 !important;
}

/* Remove Paper component elevation and border */
.editor-container .MuiPaper-root {
  box-shadow: none !important;
  border-radius: 0 !important;
  border: none !important;
}

/* Remove internal borders from toolbar rows */
.editor-container .MuiPaper-root > .MuiBox-root {
  /* border-bottom: none !important; */
}

/* Remove padding from button groups */
.toolbar .MuiButtonGroup-root {
  margin: 0 !important;
}

/* Optional: Remove dividers if needed */
.toolbar .MuiDivider-root {
  /* display: none !important; */
}

/* Page layout styles */
.tiptap-editor.layout-default {
  max-width: 8.5in;
  margin: 0 auto;
  padding: 1in;
}

.tiptap-editor.layout-wide {
  max-width: 11in;
  margin: 0 auto;
  padding: 1in;
}

.tiptap-editor.layout-narrow {
  max-width: 6in;
  margin: 0 auto;
  padding: 1in;
}

.tiptap-editor.layout-column-2 {
  column-count: 2;
  column-gap: 2rem;
  padding: 1in;
}

.tiptap-editor.layout-column-3 {
  column-count: 3;
  column-gap: 1.5rem;
  padding: 1in;
}